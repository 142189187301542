/**
 * General
**/

.scroller {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.scrolling {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  display: inline-block;
  text-decoration: none;
}

a span {
  position: relative;
  font-style: italic;
  color: #000;
}

a span::after {
  position: absolute;
  bottom: 0.1em;
  content: "";
  display: inline-block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000000;
  /* transform: scale3d(0,1,1); */
  transition: transform 0.5s ease;
}

html,
body {
  overflow: hidden;
  background-color: #ffffff;
}

.text-cont p {
  margin-top: 1.2rem;
}

b {
  font-weight: 600;
}

.cont {
  padding: 3rem 3rem 3rem 3rem;
  grid-column: span 2;
}

.cont-left {
  padding: 3rem 1.5rem 3rem 3rem;
}

.cont-right {
  padding: 3rem 3rem 3rem 1.5rem;
}

.img-cont {
  overflow: hidden;
  position: relative;
}

.img-cont img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-desktop {
  display: grid;
  grid-template-columns: 50% 50%;
}

/**
 * Text
**/

.text-serif,
.text-sans,
.contact-form label,
.contact-form input,
.contact-form textarea,
.contact-form select {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-size: 1rem;
  font-size: clamp(1.2rem, 2vw, 2rem);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.text-sans,
p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
}

.text-serif {
  font-family: "Italiana", serif;
  font-weight: 400;
}

.text-big {
  font-size: 4rem;
  font-size: clamp(5rem, 14vw, 14vw);
  line-height: 0.8;
}

.text-small {
  font-size: 2rem;
  font-size: clamp(2.5rem, 7vw, 7vw);
  line-height: 0.8;
}

.text-header {
  font-size: 2.7rem;
  font-size: clamp(2.7rem, 5.6vw, 5.6vw);
  line-height: 0.8;
}

.text-white {
  color: #fff;
}

/**
 * Nav-Bar
**/

.nav-bar {
  position: fixed;
  z-index: 10;
  top: 0%;
  left: 0%;
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  justify-content: space-between;
  mix-blend-mode: difference;
}

.nav-bar .top-logo {
  height: 5rem;
  width: auto;
  padding: 0.5rem 2rem;
  fill: white;
}

.nav-bar nav {
  max-width: 80ch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}

.nav-bar a {
  color: inherit;
  font-size: 1.2rem;
  white-space: nowrap;
  margin: 1rem;
}

.nav-bar a::after,
.nav-bar::before {
  background-color: #fff;
}

.nav-bar .cta span::after {
  background-color: #ffffff;
}

/**
 * Header
**/

header {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

header h2 {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 45vh;
  left: 50vw;
}

.la-casa,
.de-tus,
.sueños,
.hero-ubi {
  position: absolute;
  transform: translate(-50%, -50%);
  color: #ffffff;
}

.la-casa {
  top: -0.65em;
  left: -0.6em;
  width: 5ch;
}

.de-tus {
  top: 0;
  left: 0;
  width: 6ch;
}

.de-tus::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.3em;
  left: -0.6em;
  height: 0.38em;
  width: 0.38em;
  background-color: #fff;
  border-radius: 50%;
}

.sueños {
  top: 0.5em;
  left: 1.3ch;
}

.hero-ubi {
  font-size: 0.312em;
  width: 10.5ch;
  top: 3.44em;
  left: 4.575em;
}

.hero-ubi::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.3em;
  left: -0.6em;
  height: 0.38em;
  width: 0.38em;
  background-color: #fff;
  border-radius: 50%;
}

header .hero-img {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
}

/**
 * Main
**/

main {
  position: relative;
}

.initial {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 42% auto;
}

.initial .cont-left {
  padding-top: 6rem;
}

.initial .cont-right {
  margin: 6rem 3rem 6rem 1.5rem;
  padding: 0;
}

.initial h2 {
  margin-left: -0.2ch;
  margin-bottom: 2.5rem;
}

b.tiempo-limitado {
  text-decoration: line-through;
  font-weight: 400;
}

p.tiempo-limitado {
  margin-top: initial;
  background: black;
  color: white;
  transform: scale(0.8);
  transform-origin: left;
  display: inline-block;
  padding: 0.1em 0.2em;
}

.initial .cta {
  margin-top: 2rem;
}

.floor {
  display: grid;
  width: 100vw;
  position: relative;
  grid-template-columns: 33% 67%;
  grid-template-columns: auto auto;
}

.floor-blueprint {
  aspect-ratio: 203/361;
  height: 100vh;
  min-width: 59vh;
  max-width: 100vw;
}

.floor-blueprint .floor-img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.floor-pin {
  max-width: 100vw;
}

.slider {
  padding-top: 7rem;
}

.floor-title {
  font-family: "Italiana", serif;
  font-weight: 400;
  font-size: 2rem;
  font-size: clamp(3.2rem, 5.5vw, 5.5vw);
  line-height: 0.8;
  position: absolute;
  top: 0.68em;
  border-bottom: 1px black solid;
}

.slider .img-cont {
  aspect-ratio: 16/9;
  width: 100%;
}

.slider-img {
  width: 100%;
  aspect-ratio: 16/9;
  height: auto;
}

.floor p {
  font-family: "Italiana", serif;
  font-size: 2rem;
  /* font-size: clamp(1.6rem, 3.85vw, 3.85vw); */
  position: relative;
  z-index: 2;
  margin-top: 1.4rem;
  margin-bottom: 0.1em;
}

.pool {
  width: 100vw;
  height: 100vh;
}

.pool > div {
  position: relative;
  width: 100%;
  height: 100%;
  transform: scale(1);
}

.pool h4 {
  position: absolute;
  z-index: 2;
  bottom: 0;
  text-align: right;
  right: 0;
  width: 15ch;
  color: #fff;
  padding: 3rem;
}

.pool img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/**
 * Forms
**/

.contact-form {
  display: grid;
  grid-template-columns: 50% 50%;
}

.contact-form .cont {
  padding-top: 2em;
}

.contact-form:first-child {
  padding-top: 5rem;
}

.contact-form .cont-left,
.contact-form .cont-right {
  padding-top: 0;
  padding-bottom: 0;
}

.contact-form label,
.contact-form input,
.contact-form textarea,
.contact-form select,
.form-text {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  display: block;
  margin-bottom: 0.2em;
  width: 100%;
  border-radius: 0;
  border: none;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  margin: 0.2em 0em 0.4rem;
  padding: 0em 0.2em;
  border-bottom: 1px solid #000000;
  outline-offset: 0;
  outline-color: gray;
}

.contact-form label {
  transition: all 0.3s;
  transform: translateY(1.4em);
}

.contact-form .filled {
  transform: translateY(0);
}

.contact-form .checkbox {
  display: inline-block;
  width: auto;
}

.contact-form input[type="button"],
.contact-form input[type="submit"] {
  background-color: #000;
  color: #fff;
  transition: transform 0.5s;
  border-radius: 0;
  border: none;
  padding: 0.4em 0.2em;
  cursor: pointer;
}

.contact-form input[type="button"]:hover,
.contact-form input[type="submit"]:hover {
  transform: scale(1.05) !important;
}

.form-hide {
  overflow: hidden;
  height: 5.2em;
  transition: height 0.5s ease;
}

.form-hide.active {
  height: 0em;
}

.radio {
  margin-top: initial !important;
}

.grid {
  display: grid;
  grid-template-columns: 50% 50%;
}

.grid-auto {
  display: grid;
  grid-template-columns: auto auto;
}

.check-flex {
  display: flex;
  align-items: center;
  margin-top: 0.1rem;
}

.check-flex input {
  margin-right: 1ch;
}

.grid-double {
  grid-column: span 2;
}

.check-cont {
  margin-top: 0.2rem;
}

/**
 * Footer
**/

footer .iframe-cont {
  width: 100%;
  aspect-ratio: 16/9;
}

footer .iframe-cont iframe {
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.grid-desktop p.text-serif {
  margin-bottom: 3rem;
}

.contact .cta {
  margin-top: 1rem;
}

.cta {
  transform: translate(0, 0);
  transition: transform 0.3s;
}

.cta .arrow {
  height: 1.44em;
  transform: translate(-2px, 25%);
  transition: transform 0.3s;
  margin-top: -2em;
  border-radius: 30%;
}

.cta:hover {
  transform: translate(10%, 0%);
}

.cta:hover .arrow {
  transform: translate(10%, 25%) scaleX(1.2);
}

footer {
  padding-bottom: 4rem;
}

.whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  aspect-ratio: 1/1;
  width: 3.5rem;
}
.whatsapp img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/**
 * Media Queries
**/

@media (max-aspect-ratio: 4/3) {
  .grid-desktop,
  .initial,
  .floor,
  .contact-form,
  .grid-auto {
    grid-template-columns: auto;
  }
  .grid-auto .grid-double {
    grid-column: span 1;
  }
  .cont {
    grid-column: span 1;
  }
  .mobile-hide {
    display: none;
  }
  .cont-left,
  .cont-right,
  .cont {
    padding: 2rem 1.5rem 1rem 1.5rem;
  }
  .contact-form .cont-right {
    padding-top: 2rem;
  }
  .mobile-hide {
    display: none;
  }
  .slider {
    padding-top: 0rem;
  }
  .floor-blueprint img {
    display: block;
    margin: auto;
  }
  .floor-pin {
    padding-top: 3rem;
  }
  .pool {
    margin-top: 2rem;
    margin-bottom: 8rem;
    height: auto;
  }
  .pool h4 {
    top: 100%;
    bottom: unset;
    color: #000;
    width: 100%;
    text-align: left;
    padding: 3rem 1.5rem;
  }
  .nav-bar .top-logo {
    padding: 0.5rem 1rem;
  }
  .nav-bar nav {
    padding-right: 0rem;
  }
  .floor-blueprint {
    aspect-ratio: 203/361;
    height: initial;
    min-width: initial;
    max-width: initial;
  }
}

@media only screen and (max-width: 500px) {
  .hero-ubi {
    /* font-size: 2rem;
        font-size: clamp(2.5rem, 7vw, 7vw);
        top: 2.4em;
        left: 0.5em; */
    top: 3.6em;
    left: 3.85em;
  }
}
